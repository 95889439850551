import React from "react";
import '../styles/feedbackSection.css'

function Feedback() {
    return (
        <div className="feedback-wrapper">

            <div className="titles">
                <div className="t-title">Our customers' feedback about us</div>
                <div className="b-title">their impressions after using our application</div>
            </div>
            
            <div className="feedback-info">


                <div className="section-testimony">
                    <div className="testimony">
                    “Vaccine Genie has an amazing website and app
    for me to feel better or get medical help.
    It offers me a 24/7 medical information service 
    without any hassle. Awesome.”
                    </div>

                    <div className="person">
                        <div className="person-info">
                            <div className="name">Arsheya Raj</div>
                            <div className="uni">International Student at the University of Washington</div>

                            <div className="arrows"> </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default Feedback;