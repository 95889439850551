import { initializeApp, getApps, getApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyDQu2OiqML61jkgdSzW8UNNG5QrHnHS1vA",
  authDomain: "local-vg-website.firebaseapp.com",
  databaseURL: "https://local-vg-website-default-rtdb.firebaseio.com",
  projectId: "local-vg-website",
  storageBucket: "local-vg-website.appspot.com",
  messagingSenderId: "939720333411",
  appId: "1:939720333411:web:1880588738f0ccdd2f4056",
  measurementId: "G-9V19DD4QZ2"
};

// Initialize Firebase
let app = initializeApp(firebaseConfig);
const storage = getStorage(app);


export { storage, app };