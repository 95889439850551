
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Parse from './parseAndFillTable';
import '../styles/uploadForm.css';
import UploadFile from './uploadFile';
import { ReactComponent as Upload } from '../icons/upload.svg';
import { ReactComponent as Drag } from '../icons/cloud-add.svg';

function UploadForm() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [compiledOcrText, setCompiledOcrText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [progressStep, setProgressStep] = useState(0); // Progress step for the progress bar

  const stages = [
    'Uploading Files',
    'Scanning Files',
    'Processing Data',
    'Filling Table',
    'Results Ready',
  ];

  const uploadFilesAndRunOCR = async (files) => {
    setIsLoading(true);
    setProgressStep(0); // Start at "Uploading Files"

    let compiledText = '';
    for (const file of files) {
      try {
        // Step 1: Upload files
        setProgressStep(1);
        const storageRef = ref(storage, `files/${file.name}`);
        const uploadTask = await uploadBytesResumable(storageRef, file);
        const downloadURL = await getDownloadURL(uploadTask.ref);

        // Step 2: Perform OCR scanning
        setProgressStep(2);
        const ocrData = await fetchOCRData(downloadURL);
        compiledText += `${ocrData.text}
`;
      } catch (error) {
        console.error('Error processing file:', file.name, error);
      }
    }

    // Step 3: Process Data
    setProgressStep(3);
    setCompiledOcrText(compiledText);
  };

  //const BASE_URL = "http://localhost:5001/vaccine-genie/us-central1/api";
  const BASE_URL = "https://vaccinegenie.com/api";

  const fetchOCRData = async (downloadURL) => {
    const response = await fetch(`${BASE_URL}/ocr`, {
      method: 'POST',
      body: JSON.stringify({ fileUrl: downloadURL }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to process OCR');
    }

    return response.json();
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const handleConvertClick = () => {
    if (selectedFiles.length === 0) {
      alert('Please upload files before clicking Convert.');
      return;
    }

    uploadFilesAndRunOCR(selectedFiles);
  };

  const handleParseComplete = () => {
    setProgressStep(4); // Update to "Results Ready"
    setIsLoading(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    multiple: true,
  });

  return (
    <div className="wrapper-upload">
      <div className="upload-header">
        <div id="upload-logo">
          <Upload />
        </div>
        <div className="upload-text">
          <span id="top-text">Upload files</span>
          <span id="lower-text">Select and upload multiple files</span>
        </div>
      </div>

      <div className="drag-drop-area" {...getRootProps()}>
        <Drag />
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className="drag-and-drop-info">
          <span id="drag-and-drop-top-text">Choose a file or drag and drop them here</span>
          <span id="drag-and-drop-bot-text">JPEG, PNG, and PDF formats, up to 50MB</span>
        </div>
        ) : (
          <div className="drag-and-drop-info">
            <span id="drag-and-drop-top-text">Choose a file or drag and drop them here</span>
            <span id="drag-and-drop-bot-text">JPEG, PNG, and PDF formats, up to 50MB</span>
          </div>
        )}
        <label id="browse-files">Browse Files</label>
      </div>

      <div className="selected-files">
        <div className="files">
          {selectedFiles.map((file) => (
            <UploadFile
              key={file.name}
              file={file}
              onRemove={() => {
                setSelectedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
              }}
            />
          ))}
        </div>
      </div>

  
      <div className='button-container'>
        <button
          id='convert-button'
          onClick={handleConvertClick}
          disabled={isLoading || selectedFiles.length === 0}
        >
          Convert
        </button>
      </div>

      {isLoading && progressStep < stages.length - 1 && (
          <div className="spinner-container">
              <div className="spinner"></div>
              <span className='stage-text'>{stages[progressStep]}</span>
          </div>
      )}

      <div className='table-component'>
      {compiledOcrText && (
        <Parse
          ocrText={compiledOcrText}
          onDataParsed={(data) => console.log('Parsed Data:', data)}
          onParseComplete={handleParseComplete} // Callback for parse completion
        />
      )}
      </div>
    </div>
  );
}

export default UploadForm;